@use 'colors.module';

.notFoundWrapper {
  width: 100%;
  height: 93vh;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.imgBox {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;

  & > img {
    width: 15%;
  }

  &.small > img {
    width: 40%;
  }
}
