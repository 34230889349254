$opacity: 0.9;
// ------------------------------------------- Color Shades
// ---------------------------------------- Black
$abbeyBlack: #494f55; // Abbey Black
$charcoalBlack: #36454f; // Charcoal Black
$eelBg: #463e3f; // Eel Black

// ---------------------------------------- White
$ghostWhite: #f8f8ff;
$snowWhite: #f5fefd;
$linkWhite: #ecf3f9;

// ----------------------------------------- Grey
$lightSlateGrey: #778899;
$lightGrey: #d3d3d3;
$darkSlateGrey: #2f4f4f;
$darkGrey: #474747;

// --------------------------------------------- App Colors
$blog-orange: #ff6600;
$blog-purple: #5e56ff;
$js-yellow: #fdd835;
$my-blue: #1e88e5;
$java-red: #d32f2f;
$spring-green: #388e3c;
$github-black: #171515;

// ------------------------------- Text Blend
$textBlend: #b0b0b0;

// ---------------------------------------- Light
$textLight: $abbeyBlack;
$textAltLight: $eelBg;
$bgLight: $snowWhite;
$contrastLight: #e7f0ed;
$lightHighlight: #ff6600;
$navLight: rgba($snowWhite, $opacity);
$navLightHidden: rgba($snowWhite, 0);

// ----------------------------------------- Dark
$textDark: $snowWhite;
$textAltDark: $linkWhite;
$bgDark: $abbeyBlack;
$contrastDark: #334e42;
$darkHighlight: #c6e100;
$navDark: rgba($abbeyBlack, $opacity);
$navDarkHidden: rgba($abbeyBlack, 0);
