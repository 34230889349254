.fbShadow {
  filter: drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.5));
}

.fbWrapper {
  position: relative;
  width: 99vw;
  height: 100%;
  padding: 1rem 0;
  margin: 1rem 0;

  &.light {
    background: linear-gradient(
      135deg,
      #ff6600,
      #f09559,
      #ffae78,
      #59e7f0,
      #0099ff
    );
  }

  &.dark {
    background: linear-gradient(135deg, #334e42, #269a97, #0099ff);
  }

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background-image: url("data:image/svg+xml,%3Csvg width='52' height='26' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%2336454f' fill-opacity='0.14'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    //background-size: cover;
    filter: grayscale(100%);
  }
}

.fbWrapperInside {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
}
