// used from https://codepen.io/iamsaief/pen/jObaoKo
@use 'colors.module';

.grid-wrapper {
  display: grid;
  grid-gap: 0.1em;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-auto-rows: 200px;
  grid-auto-flow: dense;
  margin: 1%;
}

.grid-wrapper > div {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;

  &.light {
    background-color: colors.$darkHighlight;
  }

  &.dark {
    background-color: colors.$lightHighlight;
  }
}

.grid-wrapper > div:nth-child(3n) {
  grid-column: span 2;
}

.grid-wrapper > div:nth-child(5n) {
  grid-row: span 2;
}
