@use 'colors.module';

.wrapper {
  z-index: 50;
  width: 100%;
  min-height: 2rem;
  padding: 0.3% 0 0.7% 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  align-content: center;
  align-items: center;

  &.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  &.light {
    background: colors.$navLight;
  }

  &.light.hidden {
    background: colors.$navLightHidden;
  }

  &.dark {
    background: colors.$navDark;
  }

  &.dark.hidden {
    background: colors.$navDarkHidden;
  }

  > .options {
    display: flex;
    flex-flow: row nowrap;

    &.mobile {
      flex-flow: column nowrap;
    }
  }

  > .options > .navItem {
    z-index: 1;

    &:hover {
      cursor: pointer;
    }

    &.hide {
      opacity: 0;
      display: none;
      z-index: -50;
    }
  }

  .hide {
    display: none;
  }
}
