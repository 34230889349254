@use 'colors.module';

ul {
  background: inherit;
  list-style: none;
  margin: 0;
  padding-left: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
}

li {
  display: block;
  float: left;
  padding: 0 1.3rem;
  position: relative;
  text-decoration: none;
  transition-duration: 0.5s;
}

li:hover,
li:focus-within {
  cursor: pointer;

  &.light {
    background: rgba(colors.$lightHighlight, 0.9);
  }

  &.dark {
    background: rgba(colors.$darkHighlight, 0.9);
  }
}

ul li ul {
  visibility: hidden;
  opacity: 0;
  width: 100%;
  position: absolute;
  transition: all 0.5s ease;
  left: 0;
  display: none;

  &.light {
    background: rgba(colors.$lightHighlight, 0.8);
  }

  &.dark {
    background: rgba(colors.$darkHighlight, 0.8);
  }
}

ul li:hover > ul,
ul li:focus-within > ul,
ul li ul:hover,
ul li ul:focus {
  visibility: visible;
  opacity: 1;
  display: block;
}

.dropdown li {
  clear: both;
  width: 78%;
}
